import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

// import rainbowSDK from 'rainbow-web-sdk';
import { rejects } from "assert";

@Injectable({
	providedIn: "root",
})
export class RainbowService {
	credencialesRainbow: BehaviorSubject<any>;
	onLogin: Subject<any>;

	onNuevoMensajeRecibido: Subject<any>;
	onNuevoImEstadoParticipante: Subject<any>;
	onNuevoImEstadoMensaje: Subject<any>;

	onChatBorrado: Subject<any>;
	onNuevoMensajeChat: Subject<any>;
	onChatChanged: Subject<any>;
	onContadorMensajesNoLeidos: Subject<any>;

	onNuevoEstadoContacto: Subject<any>;
	onNuevoEstadoUsuario: Subject<any>;

	/**
	 * @param {DOCUMENT} document
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _fuseNavigationService: FuseNavigationService,
	) {
		this.credencialesRainbow = new BehaviorSubject(null);
		this.onLogin = new Subject();

		this.onNuevoMensajeRecibido = new Subject();
		this.onNuevoImEstadoParticipante = new Subject();
		this.onNuevoImEstadoMensaje = new Subject();

		this.onChatBorrado = new Subject();
		this.onNuevoMensajeChat = new Subject();
		this.onChatChanged = new Subject();
		this.onContadorMensajesNoLeidos = new Subject();

		this.onNuevoEstadoContacto = new Subject();
		this.onNuevoEstadoUsuario = new Subject();
	}

	init() {
		// this.document.addEventListener(rainbowSDK.RAINBOW_ONREADY, (() => this.onReady()));
		// this.document.addEventListener(rainbowSDK.RAINBOW_ONLOADED, this.onLoaded);
		// rainbowSDK.start();
		// rainbowSDK.load();
	}

	private onReady() {
		console.log("[RBW] :: On SDK Ready !");
		this.getCredencialesRainbow();
	}

	private onLoaded() {
		console.log("[RBW] :: On SDK Loaded !");

		const appId = "0989ea308a0e11eaabed717bcb514caf";
		const secretKey = "KNaEtKEdLZ09NxmwfV6HUiSiB1DuSggg6VXrDiymA5psOyQgCjmBU6JysLXXMLrX";

		// rainbowSDK
		// 	.initialize(appId, secretKey)
		// 	.then(() => {
		// 			console.log('[RBW] :: Rainbow SDK is initialized!');
		// 		})
		// 		.catch(err => {
		// 			console.log('[RBW] :: Something went wrong with the SDK.', err);
		// 		});
	}

	private getCredencialesRainbow() {
		// A traves del usuario logado obtener el usuario de rainbow asociado
		// Llamar a un ws para obtener los datos del usuario rainbow.
		const userRbw = "jagcycsi@gmail.com";
		const pwdRbw = "User1234$";
		this.credencialesRainbow.next({ user: userRbw, pwd: pwdRbw });
	}

	// getDataUser(): Observable<any> {
	// 	return this.credencialesRainbow.asObservable();
	// }

	login(userRbw, pwdRbw) {
		// Listen when the SDK is signed
		// this.document.addEventListener(rainbowSDK.connection.RAINBOW_ONSIGNED, ((event:any) => this.onSigned(event)));
		// Listen when the SDK is started
		// this.document.addEventListener(rainbowSDK.connection.RAINBOW_ONSTARTED, this.onStarted);

		this.addListeners();

		// rainbowSDK.connection.signin(userRbw, pwdRbw)
		// // rainbowSDK.connection.signinOnRainbowOfficial(userRbw, pwdRbw)
		// 	.then((account:any) => {
		// 		// Successfully signed to Rainbow and the SDK is started completely. Rainbow data can be retrieved.
		// 		console.log("[RBW] :: Login", account);
		// 		this.onLogin.next(account);
		// 	})
		// 	.catch(function(err) {
		// 		// An error occurs (e.g. bad credentials). Application could be informed that sign in has failed
		// 		console.log("[RBW] :: Error login", err);
		// 		//this.onLogin.error(err);
		// 	});
	}

	addListeners() {
		// // Presence
		// this.document.addEventListener(rainbowSDK.presence.RAINBOW_ONCONTACTRICHPRESENCECHANGED, this.onContactRichPresenceChanged);
		// this.document.addEventListener(rainbowSDK.presence.RAINBOW_ONRICHPRESENCECHANGED, this.onRichPresenceChanged);
		// // this.document.addEventListener(rainbowSDK.presence.RAINBOW_ONCONTACTPRESENCECHANGED, this.onContactPresenceChanged);
		// // this.document.addEventListener(rainbowSDK.presence.RAINBOW_ONPRESENCECHANGED, this.onPresenceChanged);
		// // Conversation
		// this.document.addEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONREMOVED, this.onConversationRemoved);
		// this.document.addEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONCHANGED, this.onConversationChanged);
		// this.document.addEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSCHANGED, this.onConversationsChanged);
		// this.document.addEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSMISSEDCOUNTERCHANGED, this.onConversationsMissedCounterChanged);
		// // IM
		// this.document.addEventListener(rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onNewMessageReceived);
		// this.document.addEventListener(rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus);
		// this.document.addEventListener(rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onNewMessageReceiptReceived);
	}

	removeListeners() {
		// // Presence
		// this.document.removeEventListener(rainbowSDK.presence.RAINBOW_ONCONTACTRICHPRESENCECHANGED, this.onContactRichPresenceChanged);
		// this.document.removeEventListener(rainbowSDK.presence.RAINBOW_ONRICHPRESENCECHANGED, this.onRichPresenceChanged);
		// // this.document.removeEventListener(rainbowSDK.presence.RAINBOW_ONCONTACTPRESENCECHANGED, this.onContactPresenceChanged);
		// // this.document.removeEventListener(rainbowSDK.presence.RAINBOW_ONPRESENCECHANGED, this.onPresenceChanged);
		// // Conversation
		// this.document.removeEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONREMOVED, this.onConversationRemoved);
		// this.document.removeEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONCHANGED, this.onConversationChanged);
		// this.document.removeEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSCHANGED, this.onConversationsChanged);
		// this.document.removeEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSMISSEDCOUNTERCHANGED, this.onConversationsMissedCounterChanged);
		// // IM
		// this.document.removeEventListener(rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onNewMessageReceived);
		// this.document.removeEventListener(rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus)
		// this.document.removeEventListener(rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onNewMessageReceiptReceived);
	}

	// getLoginRbw(): Observable<any> {
	// 	return this.onLogin.asObservable();
	// }

	private onSigned(event) {
		let account = event.detail;
		// Authentication has been performed successfully. Account information could be retrieved.
		console.log("[RBW] :: OnSigned - Login correcto", account);
	}

	private onStarted(event) {
		// Do something once the SDK is ready to call Rainbow services
		console.log("[RBW] :: OnStarted - SDK is ready to call Rainbow services");
	}

	getProfile(): Promise<any> {
		return new Promise((resolve, reject) => {
			// rainbowSDK.userProfile.getProfile()
			//     .then(profile => {
			// 		console.log('[RBW] :: getProfile', profile);
			// 		resolve(profile);
			//     });
		});
	}
	getConectedUser(): Promise<any> {
		return new Promise((resolve, reject) => {
			// let user = rainbowSDK.contacts.getConnectedUser();
			// console.log('[RBW] :: getConnectedUser', user);
			// resolve(user);
		});
	}

	setUserStatus(newStatus) {
		// const result = rainbowSDK.presence.setPresenceTo(newStatus);
		// console.log('[RBW] :: setPresenceTo', result);
	}

	getContats = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			// rainbowSDK.contacts.getNetworkContacts()
			//     .then(listContacts => {
			// 		console.log("[RBW] :: getNetworkContacts", listContacts);
			// 		resolve(listContacts);
			//     });
		});
	};

	getContactById = (contactId: string): any => {
		// console.log("[RBW] :: contact ID", contactId);
		// const contact = rainbowSDK.contacts.getContactByJID(contactId);
		// console.log("[RBW] :: getContactById", contact);
		// return contact;
		// return new Promise((resolve, reject) => {
		// 	const contact = rainbowSDK.getContactById(contactId);
		// 	console.log("[RBW] :: getContactById", contact);
		// 	resolve(contact);
		// });
	};

	getConversations = () => {
		// const conversations = rainbowSDK.conversations.getAllConversations();
		// console.log("[RBW] :: getConversations", conversations);
		// return conversations;
		// return new Promise((resolve, reject) => {
		//     const conversations = rainbowSDK.conversations.getAllConversations();
		//     console.log("[RBW] :: getConversations", conversations);
		// 	resolve(conversations);
		// });
	};

	getConversationById(id: string): any {
		// const conversation = rainbowSDK.conversations.getConversationById(id);
		// console.log("[RBW] :: getConversationById", conversation);
		// return conversation;
		// return new Promise((resolve, reject) => {
		// 	const conversation = rainbowSDK.conversations.getConversationById(id);
		// 	console.log("[RBW] :: getConversationById", conversation);
		// 	resolve(conversation);
		// });
	}

	openConversation = (contact: any): Promise<any> => {
		return new Promise((resolve, reject) => {
			// 	rainbowSDK.conversations.openConversationForContact(contact)
			// 		.then(conversation => {
			// 			console.log("[RBW] :: openConversationForContact", conversation);
			// 			resolve(conversation);
			// 		});
		});
	};

	getMessagesFromConversation = (conversation, numMessages): Promise<any> => {
		return new Promise((resolve, reject) => {
			// 	rainbowSDK.im.getMessagesFromConversation(conversation, numMessages)
			// 		.then((conversation) => {
			// 			console.log("[RBW] :: getMessagesFromConversation", conversation);
			// 			resolve(conversation);
			// 		},
			// 		(err) => {
			// 			console.log("[RBW] :: error getMessagesConverstaion", err);
			// 			reject(err);
			// 		});
		});
	};

	sendMessageToConversation = (conversation, msgContent) => {
		// const message = rainbowSDK.im.sendMessageToConversation(conversation, msgContent);
		// return message;
	};

	markMessageFromConversationAsRead = (conversation, message) => {
		// rainbowSDK.im.markMessageFromConversationAsRead(conversation, message);
	};

	private onNewMessageReceived = (event) => {
		console.log("[RBW] :: onNewMessageReceived", event);
		this.onNuevoMensajeRecibido.next({
			message: event.detail.message,
			conversation: event.detail.conversation,
		});
	};

	private onNewParticipantStatus = (event) => {
		console.log("[RBW] :: onNewParticipantStatus", event);
		console.log("[RBW] :: nuevo estado", event.detail.status);
		//Status: active, composing, paused
		this.onNuevoImEstadoParticipante.next({
			participant: event.detail.participant,
			conversation: event.detail.conversation,
			status: event.detail.status,
		});
	};

	private onNewMessageReceiptReceived = (event) => {
		console.log("[RBW] :: onNewMessageReceiptReceived", event);
		//Status: server, received or read
		this.onNuevoImEstadoMensaje.next({
			message: event.detail.message,
			conversation: event.detail.conversation,
			status: event.detail.evt,
		});
	};

	private onConversationRemoved = (event) => {
		console.log("[RBW] :: onConversationRemoved", event);
		//Status: server, received or read
		this.onChatBorrado.next({
			conversation: event.detail,
		});
	};
	private onConversationChanged = (event) => {
		console.log("[RBW] :: onConversationChanged", event);
		//Status: server, received or read
		this.onNuevoMensajeChat.next({
			conversationId: event.detail,
		});
	};
	private onConversationsChanged = (event) => {
		console.log("[RBW] :: onConversationsChanged", event);
		//Status: server, received or read
		this.onChatChanged.next({
			conversation: event.detail,
		});
	};
	private onConversationsMissedCounterChanged = (event) => {
		console.log("[RBW] :: onConversationsMissedCounterChanged", event);
		this._fuseNavigationService.updateNavigationItem("chat", {
			badge: {
				title: event.detail.missedIMCounter,
			},
		});

		this.onContadorMensajesNoLeidos.next({
			counter: event.detail.missedIMCounter,
		});
	};

	private onContactRichPresenceChanged = (event) => {
		console.log("[RBW] :: onContactRichPresenceChanged", event);
		//Status: busy, offline, away or online
		this.onNuevoEstadoContacto.next({
			contact: event.detail,
		});
	};
	private onRichPresenceChanged = (event) => {
		console.log("[RBW] :: onRichPresenceChanged", event);
		//Status: busy, offline, away or online
		this.onNuevoEstadoUsuario.next({
			contact: event.detail,
		});
	};
	// private onContactPresenceChanged = (event) => {
	// 	console.log("[RBW] :: onContactPresenceChanged", event);
	// 	//Status: dnd, offline, away or online
	// 	this.onNuevoEstadoContacto.next({
	// 		jid: event.detail.jid,
	// 		status: event.detail.status
	// 	});
	// }
	// private onPresenceChanged = (event) => {
	// 	console.log("[RBW] :: onPresenceChanged", event);
	// 	//Status: dnd, offline, away or online
	// 	this.onNuevoEstadoUsuario.next({
	// 		jid: event.detail.jid,
	// 		status: event.detail.status
	// 	});
	// }
}
